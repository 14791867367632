.main-skills{
  display: flex;
  flex-direction: column;
  position: relative;
  .section-container-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    .background{
      width: 100%;
      height: 446px;
      position: relative;
      @include small-device{
        height: 777px;
      }
      @include mobile{
        height: 600px;
      }
      .background-img{
        width: 100%;
        height: 100%;
        @include tablet{
          width: 300%;
        }
        @include mobile{
          width: 100%;
        }
      }
      .background-cover{
        position: absolute;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        width: 100%;
        height: 100%;
      }
    }
    .container-header {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 180px;
      z-index: 1;
      @include small-device {
        flex-direction: column;
      }
      @include tablet{
        top: 332px;
      }
      @include mobile {
        padding: 0 20px;
        top: 196px;
      }

      .title-header-about {
        font-size: 48px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 120px;
        @include font-kaiseidecol-bold;
        @include tablet{
          font-size: 67px;
          margin-bottom: 253px;
        }
        @include mobile{
          font-size: 30px;
          margin-bottom: 61px;
        }
      }

      .container-button {
        display: flex;
        align-items: center;
        @include mobile{
          flex-direction: column;
        }
        .button-header {
          margin-right: 30px;
          padding: 10px;
          background: #7B68EE;
          text-align: center;
          color: #FFFFFF;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
          text-decoration: none;
          @include font-poppins-bold;
          @include mobile{
            margin-right: 0;
            margin-bottom: 30px;
            width: 100%;
            font-size: 12px;
          }
        }
      }
    }
  }
  .section-1-skill{
    padding: 130px 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mobile{
      padding: 50px 20px;
      align-items: center;
    }
    .title-section-1{
      font-size: 48px;
      @include font-kaiseidecol-bold;
      margin-bottom: 10px;
      color: black;
      @include mobile{
        font-size: 30px;
        text-align: center;
      }
    }
    .subtitle{
      font-size: 24px;
      @include font-tirodevanagarihindi-italic;
      color: black;
      margin-bottom: 86px;
      @include mobile{
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    .container-logiciel{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @include mobile{
        flex-wrap: inherit;
        flex-direction: column;
        justify-content: inherit;
      }
      .container-progress{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(50% - 100px);
        margin-bottom: 10px;
        &:nth-child(2n + 2){
          margin: 0;
        }
        @include mobile{
          width: 100%;
        }
        .title-progress-bar{
          font-size: 16px;
          @include font-poppins-regular;
        }
      }
    }
  }
  .section-2-skill{
    padding: 100px 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    @include mobile{
      padding: 50px 30px;
      align-items: center;
    }
    .container-element-skill{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include mobile{
        flex-direction: column;
        justify-content: inherit;
        align-items: center;
      }
      .left{
        width: calc(50% - 35px);
        @include mobile{
          display: none;
        }
      }
      .container-right{
        width: calc(50% - 35px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include mobile{
          width: 100%;
          align-items: center;
        }
        .title-right{
          font-size:48px;
          @include font-kaiseidecol-bold;
          margin-bottom: 10px;
          @include mobile{
            font-size: 25px;
            text-align: center;
          }
        }
        .subtitle{
          font-size: 22px;
          @include font-tirodevanagarihindi-italic;
          margin-bottom: 43px;
          @include mobile{
            font-size: 16px;
            text-align: center;
          }
        }
        .container-paragraph{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .paragraph{
            font-size: 20px;
            position: relative;
            @include font-poppins-regular;
            line-height: 30px;
            @include mobile{
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 10px;
            }
            span{
              @include font-poppins-bold;
            }
            &:before{
              content: '';
              position: absolute;
              left: -20px;
              top: 10px;
              width: 10px;
              height: 10px;
              background-color: #000000;
              border-radius: 50%;
              @include mobile{
                width: 5px;
                height: 5px;
                top: 5px;
              }
            }
          }
        }
      }
    }
    .container-kpi{
      position: absolute;
      display: flex;
      align-items: center;
      padding: 16px 60px;
      background-color: #121111;
      bottom: 20%;
      right: 0;
      @include mobile{
        padding: 10px 20px;
        bottom: -7%;
      }
      .container-number{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        @include mobile{
          margin-right: 10px;
        }
        .number{
          @include font-tirodevanagarihindi-regular;
          color: #ffffff;
          font-size: 60px;
          margin-bottom: 19px;
          @include mobile{
            font-size: 25px;
            margin-bottom: 5px;
          }
        }
        .title-number{
            font-size: 20px;
            @include font-poppins-regular;
            color: #ffffff;
          @include mobile{
            font-size: 8px;
          }
        }
      }
    }
  }
  .section-3-skill{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 100px;
    @include mobile{
      padding: 100px 30px;
      align-items: center;
      justify-content: inherit;
      flex-direction: column;
    }
    .left-part{
      width: calc(50% - 40px);
      display: flex;
      flex-direction: column;
      @include mobile{
        width: 100%;

      }
      .title-part{
        @include font-kaiseidecol-bold;
        font-size: 48px;
        color: #000000;
        margin-bottom: 10px;
        @include mobile{
          font-size: 25px;
          text-align: center;
        }
      }
      .subtitle-part{
        @include font-tirodevanagarihindi-italic;
        font-size: 22px;
        color: #000000;
        margin-bottom: 43px;
        @include mobile{
          font-size: 16px;
          text-align: center;
        }
      }
      .paragraph-part{
        font-size: 20px;
        position: relative;
        @include font-poppins-regular;
        line-height: 30px;
        @include mobile{
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px;
        }
        span{
          @include font-poppins-bold;
        }
        &:before{
          content: '';
          position: absolute;
          left: -20px;
          top: 10px;
          width: 10px;
          height: 10px;
          background-color: #000000;
          border-radius: 50%;
          @include mobile{
            width: 5px;
            height: 5px;
            top: 5px;
          }
        }
      }
    }
    .right-part{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      width: calc(50% - 40px);
      @include mobile{
        margin-top: 50px;
        width: 100%;
      }
    }
  }
  .section-4{
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile{
      padding: 50px 30px;
      align-items: center;
      justify-content: inherit;
      flex-direction: column-reverse;
    }
    .left-part{
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mobile{
        width: 100%;
      }
      .container-header-left{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        .img-left-header{
          width: calc(50% - 25px);
        }
      }
      .body-img-left{
        width: 100%;
        margin-top: 20px;
      }
    }
    .right-part{
      width: calc(50% - 30px);
      display: flex;
      flex-direction: column;
      @include mobile{
        width: 100%;
      }
      .title-part{
        @include font-kaiseidecol-bold;
        font-size: 48px;
        color: #000000;
        margin-bottom: 10px;
        @include mobile{
          font-size: 25px;
          text-align: center;
        }
      }
      .subtitle-part{
        @include font-tirodevanagarihindi-italic;
        font-size: 22px;
        color: #000000;
        margin-bottom: 43px;
        @include mobile{
          font-size: 16px;
          text-align: center;
        }
      }
      .paragraph-part{
        font-size: 20px;
        position: relative;
        @include font-poppins-regular;
        line-height: 30px;
        @include mobile{
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px;
        }
        span{
          @include font-poppins-bold;
        }
        &:before{
          content: '';
          position: absolute;
          left: -20px;
          top: 10px;
          width: 10px;
          height: 10px;
          background-color: #000000;
          border-radius: 50%;
          @include mobile{
            width: 5px;
            height: 5px;
            top: 5px;
          }
        }
      }
    }
  }
}