.progress {
  width: 100%;
  height: 22px;

}

.progress-wrap {
  background: linear-gradient(to left, #7B68EE,#7B68EE, #D9D9D9);
  margin: 10px 0;
  overflow: hidden;
  position: relative;
  border-radius: 50px;

  .progress-bar {
    background: rgba(217,217,217,0.8);
    left: 0;
    position: absolute;
    top: 0;
    .number{
      position: absolute;
      left: -30px;
      color: #FFFFFF;
    }
  }

}