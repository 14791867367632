.main-section-3{
  padding: 100px 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include tablet{
    padding: 100px 20px;
  }
  @include mobile{
    padding: 50px 20px;
  }
  .fleur-right{
    position: absolute;
    right: -500px;
    top: -150px;
    transform: rotate(-55deg);
    @include tablet{
      right: -146px;
      top: 253px;
      transform: rotate(-52deg);
      width: 248px;
    }
    @include mobile{
      display: none;
    }
  }
  .title-section-3{
    font-size: 48px;
    color: black;
    font-weight: bold;
    max-width: 812px;
    margin-bottom: 65px;
    text-align: center;
    @include font-kaiseidecol-bold;
    @include mobile{
      font-size: 30px;
      margin-bottom: 30px;
    }
    span{
      @include font-tirodevanagarihindi-italic;
    }
  }
  .subtitle{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 101px;
    max-width: 781px;
    text-align: center;
    @include font-poppins-regular;
    @include mobile{
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 101px;
      max-width: 280px;
      text-align: center;
    }
  }
  .container-real{
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile{
      flex-direction: column;
    }
    .container-supp{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 37px;
      @include mobile{
        margin-right: 0;
      }
      img{
        width: 100%;
        margin-bottom: 22px;
      }
      .text-supp{
        font-size: 16px;
        font-weight: 400;
        @include font-poppins-regular;
        @include mobile{
          text-align: center;
        }
      }
    }
  }
}