.main-about{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  .background{
    width: 100%;
    height: 446px;
    position: relative;
    overflow-x:hidden;
    @include small-device{
      height: 777px;
    }
    @include mobile{
      height: 500px;
    }
    .background-img{
      width: 100%;
      height: 100%;
      @include tablet{
        width: 300%;
      }
      @include mobile{
        width: 390%;
      }
    }
    .background-cover{
      position: absolute;
      top: 0;
      background-color: rgba(0,0,0,0.6);
      width: 100%;
      height: 100%;
    }
  }
  .container-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 180px;
    z-index: 1;
    @include small-device {
      flex-direction: column;
    }
    @include tablet{
      top: 332px;
    }
    @include mobile {
      padding: 0 20px;
      top: 196px;
    }

    .title-header-about {
      font-size: 48px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 120px;
      @include font-kaiseidecol-bold;
      @include tablet{
        font-size: 67px;
        margin-bottom: 253px;
      }
      @include mobile{
        font-size: 30px;
        margin-bottom: 61px;
      }
    }

    .container-button {
      display: flex;
      align-items: center;
      @include mobile{
        flex-direction: column;
      }
      .button-header {
        margin-right: 30px;
        padding: 10px;
        background: #7B68EE;
        text-align: center;
        color: #FFFFFF;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        @include font-poppins-bold;
        @include mobile{
          margin-right: 0;
          margin-bottom: 30px;
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
  .container-section-1{
    padding: 100px 50px 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile{
      padding: 50px 20px;
    }
    .logo-container{
      display: flex;
      align-items: center;
      margin-bottom: 43px;
      .logo{
        width: 214px;
        margin-right: 30px;
        @include mobile{
          width: 124px;
          margin-right: 10px;
        }
      }
    }
    .title-explain{
      font-size: 48px;
      font-weight: 400;
      color: black;
      margin-bottom:32px;
      @include font-kaiseidecol-regular;
      @include tablet{
        font-size: 36px;
      }
      @include mobile{
        text-align: center;
        font-size: 32px;
      }
    }
    .paragraph{
      font-size: 16px;
      text-align: center;
      max-width: 781px;
      margin-bottom: 30px;
      @include font-poppins-regular;
      @include tablet{
        max-width: 700px;
      }
    }
    .container-logo-1{
      display: flex;
      flex-direction: row;
      align-items: center;
      @include mobile{
        display: none;
      }
    }
  }
  .container-section-2{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    @include tablet{
      padding: 50px 0;
    }
    @include mobile{
      padding: 50px 10px;
      flex-direction: column;
    }
    .title-section-2{
      font-size: 48px;
      color: black;
      margin-bottom: 250px;
      text-align: center;
      @include font-kaiseidecol-regular;
      @include tablet{
        font-size: 36px;
        margin-bottom: 150px;
      }
      @include mobile{
        display: none;
      }
    }
    .container-change-experiences{
      background-color: #D9D9D9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      @include mobile{
        background: none;
      }
      .container-header-experience{
        display: flex;
        align-items: center;
        position: absolute;
        top: 300px;
        @include tablet{
          top:179px;
        }
        @include mobile{
          display: none;
        }
        .img-experience{
          border-radius: 50%;
          width: 231px;
          height: 231px;
          margin-right: 30px;
          border:1px solid #7B68EE;
          background-color: #FFFFFF;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @include tablet{
            width: 130px;
            height: 130px;
          }
          .logo-experience{
            width: 80%;
          }
          .background-experience{
            position: absolute;
            top: 0;
            border-radius: 50%;
            width: 231px;
            height: 231px;
            background: rgba(79,37,133,0.68);
            @include tablet{
              width: 130px;
              height: 130px;
            }
          }
        }
      }
      .container-paragraph{
        display: flex;
        flex-direction: column;
        padding: 200px 50px 80px 50px;
        @include tablet{
          padding: 100px 25px 40px 25px;
        }
        @include mobile{
          display: none;
        }
        .title-first{
          display: flex;
          max-width: 1065px;
          align-items: flex-start;
          font-size: 48px;
          font-weight: bold;
          text-align: left;
          color: black;
          margin-bottom: 5px;
          @include font-kaiseidecol-bold;
          @include tablet{
            font-size: 30px;
          }
        }
        .subtitle{
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 35px;
          text-align: left;
          color: black;
          @include font-tirodevanagarihindi-italic;
        }
        .paragraph{
          max-width: 1060px;
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 48px;
          @include font-poppins-regular;

        }
        .container-section-2-skill{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }
      }
    }
    .container-experiences{
      display: none;
      flex-direction: column;
      align-items: center;
      @include mobile{
        display: flex;
      }
      .container-experience{
        display: flex;
        flex-direction: column;
        margin-bottom: 90px;
        position: relative;
        background-color: #D9D9D9;
        max-width: 90%;
        border-radius: 15px;
        padding: 10px;
        .img-experience{
          position: absolute;
          border-radius: 50%;
          width: 151px;
          left: 50%;
          transform: translateX(-50%);
          top: -80px;
          height: 151px;
          margin-right: 30px;
          border:1px solid #7B68EE;
          background-color: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .logo-experience{
            width: 80%;
          }
          .background-experience{
            position: absolute;
            top: 0;
            border-radius: 50%;
            width: 151px;
            height: 151px;
            border: 1px solid #7B68EE;
          }
        }
        .title-first{
          display: flex;
          max-width: 1065px;
          align-items: flex-start;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          color: black;
          margin-bottom: 5px;
          margin-top: 80px;
          @include font-kaiseidecol-bold;
        }
        .subtitle{
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 20px;
          color: black;
          @include font-tirodevanagarihindi-italic;
        }
        .paragraph{
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 48px;
          @include font-poppins-regular;
          padding: 0 20px;
          text-align: center;

        }
        .container-section-2-skill{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

        }
      }
    }
  }
  .section-3{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1440px;
    @include mobile{
      padding: 50px 0;
    }
    .title-section{
      font-size: 48px;
      color: black;
      margin-bottom: 120px;
      z-index: 1;
      @include font-kaiseidecol-regular;
      @include tablet{
        margin-bottom: 50px;
      }
      @include mobile{
        font-size: 25px;
        margin-bottom: 72px;
      }
    }

    .container-section{
      margin: 0 auto;
      max-width: 1429px;
      position: relative;
      padding: 0 230px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 38px;
      @include small-device{
        flex-wrap: inherit;
        flex-direction: column;
        justify-content: inherit;
      }
      @include tablet{
        padding: 0 50px;
      }
      @include mobile{
        flex-wrap: inherit;
        flex-direction: column;
        padding: 0 20px;
      }
      .title-section-back{
        position: absolute;
        font-size: 230px;
        top: -25%;
        left: 5%;
        color: rgba(217,217,217,0.8);
        @include font-kaiseidecol-regular;
        @include tablet{
          position: absolute;
          font-size: 133px;
          top: -5%;
        }
        @include mobile{
          position: absolute;
          font-size: 50px;
          top: -7%;
          left: 14%;
          width: 100%;
        }
      }
      .component-section-3{
        z-index: 1;
        width: calc(50% - 30px);
        &:nth-child(2n + 2){
          margin: 10px;
        }
        border-radius: 10px;
        margin-right: 40px;
        background-color: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        @include small-device{
          width: 100%;
          margin-right: 0;
        }
        @include mobile{
          flex-direction: row;
        }
        .image-component{
          width: calc(50% - 20px);
          border-radius: 10px 0 0 10px;
          @include mobile{
            width: calc(50% - 0px);
          }
        }
        .container-text{
          width: calc(50% - 20px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include mobile{
            width: calc(50% - 0px);
            padding: 12px 15px;
          }
          .title-text-left{
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 21px;
            @include font-kaiseidecol-regular;
            @include mobile{
              font-size: 15px;
              margin-bottom: 10px;
            }
          }
          .br{
            width: 102px;
            height: 1px;
            background-color: black;
            margin-bottom: 18px;
            @include mobile{
              display: none;
            }
          }
          .paragraph{
            font-size: 12px;
            color: black;
            line-height: 14px;
            @include font-poppins-light;
            @include mobile{
              font-size: 9px;
              line-height: 12px;
            }
          }
          .container-exploit{
            margin-top: 30px;
            display: flex;
            align-items: center;
            @include mobile{
              display: none;
            }
            .img-exploit{
              width: 16px;
              height: 14px;
              margin-right: 10px;
            }
            .title-exploit{
              font-size: 9px;
              color: black;
              @include font-poppins-light;
            }
          }
        }
      }
    }
  }
}