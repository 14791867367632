.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  @include mobile{
    width: 200px;
    height: 100px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}