.main-realisation{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  .background{
    width: 100%;
    height: 446px;
    position: relative;
    @include small-device{
      height: 777px;
    }
    @include mobile{
      height: 600px;
    }
    .background-img{
      width: 100%;
      height: 100%;
      @include tablet{
        width: 300%;
      }
      @include mobile{
        width: 100%;
      }
    }
    .background-cover{
      position: absolute;
      top: 0;
      background-color: rgba(0,0,0,0.6);
      width: 100%;
      height: 100%;
    }
  }
  .container-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 180px;
    z-index: 1;
    @include small-device {
      flex-direction: column;
    }
    @include tablet{
      top: 332px;
    }
    @include mobile {
      padding: 0 20px;
      top: 196px;
    }

    .title-header-about {
      font-size: 48px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 120px;
      @include font-kaiseidecol-bold;
      @include tablet{
        font-size: 67px;
        margin-bottom: 253px;
      }
      @include mobile{
        font-size: 30px;
        margin-bottom: 61px;
      }
    }

    .container-button {
      display: flex;
      align-items: center;
      @include mobile{
        flex-direction: column;
      }
      .button-header {
        margin-right: 30px;
        padding: 10px;
        background: #7B68EE;
        text-align: center;
        color: #FFFFFF;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        @include font-poppins-bold;
        @include mobile{
          margin-right: 0;
          margin-bottom: 30px;
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
  .section-1{
    padding: 100px 0px 100px 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mobile{
      align-items: flex-start;
      padding: 50px 20px;
    }
    .container-section-1{
      background-color: #C4C4C4;
      display: flex;
      flex-direction: column;
      padding: 80px 100px 80px 80px;
      z-index: 2;
      position: relative;
      width: 90%;
      height: 100%;
      overflow: hidden;
      @include mobile{
        width: 100%;
        padding: 0;
        background: inherit;
        position: inherit;
      }
      .title-section{
        font-size: 48px;
        line-height: 69px;
        font-weight: 400;
        color: black;
        margin-bottom: 45px;
        max-width: 500px;
        @include font-kaiseidecol-regular;
        @include mobile{
          font-size: 25px;
          line-height: 28px;
          text-align: center;
          max-width: 100%;
          margin-bottom: 20px;
          margin-top: 50px;
        }
        span{
          @include font-tirodevanagarihindi-italic;
          font-weight: bold;
          @include mobile{
            font-weight: inherit;
          }
        }
      }
      .container-element{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include mobile{
          flex-direction: column;
          align-items: center;
          justify-content: inherit;
        }
        .left-element{
          display: flex;
          flex-direction: column;
          width: calc(50% - 40px);
          @include mobile{
            width: 100%;
          }
          .paragraph{
            font-size: 16px;
            color: black;
            font-weight: 400;
            line-height: 24px;
            @include font-poppins-regular;
            @include mobile{
              text-align: center;
              font-size: 14px;
              padding: 0 10px;
              line-height: 20px;
            }
          }
          .img-creat{
            width: 70%;
            left: -10%;
            position: absolute;
            top: 540px;
            @include mobile{
              display: none;
            }
          }
        }
      }
      .right{
        width: calc(50% - 40px);
        @include mobile{
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
  .section-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    @include mobile{
      align-items: flex-start;
      padding: 50px 20px;
      margin-top: 0;
    }
    .title-section-2{
      font-size: 48px;
      line-height: 69px;
      font-weight: 400;
      color: black;
      margin-bottom: 35px;
      @include font-kaiseidecol-regular;
      span{
        @include font-tirodevanagarihindi-italic;
        font-weight: bold;
        @include mobile{
          font-weight: inherit;
        }
      }
      @include mobile{
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 50px;
      }
    }
    .paragraph{
      font-size: 16px;
      color: black;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 992px;
      @include font-poppins-regular;
      @include mobile{
        text-align: center;
        font-size: 14px;
        padding: 0 10px;
        line-height: 20px;
        max-width: 100%;
      }
    }
    .swiper-section-2{
      margin-top: 50px;
      width: 100%;
    }
  }
  .section-3{
    margin: 200px 0;
    padding: 100px 100px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mobile{
      padding: 50px 20px;
      margin: 0;

    }
    .container-section-3{
      background-color: #C4C4C4;
      display: flex;
      flex-direction: column;
      padding: 80px 100px 80px 80px;
      z-index: 2;
      width: 90%;
      height: 100%;
      align-items: flex-end;
      @include mobile{
        width: 100%;
        padding: 0;
        background: inherit;
        position: inherit;
      }
      .title-section-3{
        font-size: 48px;
        line-height: 69px;
        font-weight: 300;
        color: black;
        margin-bottom: 45px;
        text-align: right;
        max-width: 580px;
        @include font-kaiseidecol-regular;
        @include mobile{
          font-size: 25px;
          line-height: 28px;
          text-align: center;
          max-width: 100%;
          margin-bottom: 20px;
          margin-top: 50px;
        }
        span{
          @include font-tirodevanagarihindi-italic;
          font-weight: bold;
          @include mobile{
            font-weight: inherit;
          }
        }
      }
      .container-element-3{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include mobile{
          flex-direction: column-reverse;
          align-items: center;
          justify-content: inherit;
        }
        .left-element{
          display: flex;
          flex-direction: column;
          width: calc(50% - 40px);
          position: relative;
          @include mobile{
            width: 100%;
          }
          .paragraph{
            font-size: 16px;
            color: black;
            font-weight: 400;
            line-height: 24px;
            text-align: right;
            @include font-poppins-regular;
            @include mobile{
              text-align: center;
              font-size: 14px;
              padding: 0 10px;
              line-height: 20px;
            }
          }

          .mockup{
            width: 900px;
            position: absolute;
            top: 274px;
            right: -193px;
            @include mobile{
              display: none;
            }
            .img-creat{
              width: 100%;
            }
            .iframe{
              position: absolute;
              top: 51%;
              left: 53%;
              width: 60%;
              height: 38%;
              transform: translate(-50%, -65%);
              border-radius: 17px;
            }
          }
        }
      }
      .right{
        width: calc(50% - 40px);
        @include mobile{
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}