$font-path: '../fonts/';

@mixin registerFont($classname, $name, $folder, $font, $weight, $style: normal) {
    $fontpath: "#{$font-path}#{$folder}/#{$font}";
    @font-face {
        font-family: "#{$name}";
        src: url('#{$fontpath}.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
        font-display: swap
    }
    .#{$classname} {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
    }
}

@include registerFont('kaiseidecol-bold', 'kaiseidecol', 'KaiseiDecol', 'KaiseiDecol-Bold', 700);
@include registerFont('kaiseidecol-medium', 'kaiseidecol', 'KaiseiDecol', 'KaiseiDecol-Medium', 500);
@include registerFont('kaiseidecol-regular', 'kaiseidecol', 'KaiseiDecol', 'KaiseiDecol-Regular', 400);

@mixin font-kaiseidecol-regular() {
    @extend .kaiseidecol-regular;
}

@mixin font-kaiseidecol-semibold() {
    @extend .kaiseidecol-semibold;
}


@mixin font-kaiseidecol-bold() {
    @extend .kaiseidecol-bold;
}

@include registerFont('tirodevanagarihindi-italic', 'tirodevanagarihindi', 'TiroDevanagariHindi', 'TiroDevanagariHindi-Italic', 400, italic);
@include registerFont('tirodevanagarihindi-regular', 'tirodevanagarihindi', 'TiroDevanagariHindi', 'TiroDevanagariHindi-Regular', 400, normal);


@mixin font-tirodevanagarihindi-italic() {
    @extend .tirodevanagarihindi-italic;
}

@mixin font-tirodevanagarihindi-regular() {
    @extend .tirodevanagarihindi-regular;
}


@include registerFont('poppins-light', 'poppins', 'Poppins', 'Poppins-Light', 300, normal);
@include registerFont('poppins-italic', 'poppins', 'Poppins', 'Poppins-Italic', 400, italic);
@include registerFont('poppins-regular', 'poppins', 'Poppins', 'Poppins-Regular', 400, normal);
@include registerFont('poppins-medium', 'poppins', 'Poppins', 'Poppins-Medium', 500, normal);
@include registerFont('poppins-bold', 'poppins', 'Poppins', 'Poppins-Bold', 700, normal);



@mixin font-poppins-light() {
    @extend .poppins-light;
}
@mixin font-poppins-italic() {
    @extend .poppins-italic;
}
@mixin font-poppins-regular() {
    @extend .poppins-regular;
}
@mixin font-poppins-medium() {
    @extend .poppins-medium;
}
@mixin font-poppins-bold() {
    @extend .poppins-bold;
}





