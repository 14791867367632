.main-footer-1{
  background-color: #c4c4c4;
  display: flex;
  flex-direction: column;
  .container-subscribe-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    @include small-device{
      flex-direction: column;
      justify-content: inherit;
    }
    @include mobile{
      padding: 20px;
    }
    .left-part-subscribe{
      width: calc(50% - 40px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @include small-device{
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .title-part-subscribe{
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 15px;
        @include small-device{
          text-align: center;
        }
        @include mobile{
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      .subtitle-subscribe{
        color: #fff;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
        line-height: 1.8;
        @include small-device{
          text-align: center;
        }
        @include mobile{
          font-size: 12px;
          line-height: 14px;
          max-width: 300px;
        }
      }
    }
    .right-part-subscribe{
      position: relative;
      width: calc(50% - 100px);
      @include small-device{
        width: 100%;
        margin-top: 20px;
      }

      .input-right-part-footer{
        color: #404040;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        height: 50px;
        display: block;
        padding-left: 25px;
        border-radius: 5px;
        background-color: #ffffff;
        border: none;
        outline: 0;
        @include mobile{
          font-size: 14px;
          height: 40px;

        }
      }
      .button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        height: 50px;
        font-weight: 600;
        padding: 0 30px;
        border-radius: 0px 5px 5px 0px;
        text-transform: capitalize;
        background-color: #7b68ee;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: none;
        @include mobile{
         padding: 0 10px;
          height: 40px;
          font-size: 12px;
        }
      }
    }
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
  }
  .container-explain-remember{
    display: flex;
    align-items: center;
    padding: 50px 50px;
    background-color: #c4c4c4;
    @include small-device{
      flex-direction: column;
      justify-content: inherit;
      padding: 50px;
    }
    @include mobile{
      padding: 40px 20px 0 20px;
    }
    .left-part-explain-remember{
      width: calc(40% - 10px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include small-device{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      .logo-part{
        width: 72px;
        height: 79px;
        margin-bottom: 22px;
        @include tablet{
          height: 100px;
          margin-bottom: 20px;
        }
        @include mobile{
          width: 63px;
          margin-bottom: 20px;
        }
      }
      .paragraph{
        color: #000000;
        font-size: 11px;
        font-weight: 400;
        max-width: 278px;
        letter-spacing: 0.2px;
        margin-bottom: 22px;
        @include font-poppins-regular;
        @include small-device{
          text-align: center;
          max-width: 80%;
          margin-bottom: 20px;
        }
        @include mobile{
          font-size: 12px;
          line-height: 16px;
        }
      }
      .container-logo{
        display: flex;
        align-items: center;
        .button{
          text-decoration: none;
          padding: 10px 0;
          font-size: 12px;
          width: 128px;
          background-color: #7B68EE;
          color: #FFFFFF;
          border: 1px solid #7B68EE;
          text-align: center;
          border-radius: 5px;
          @include font-poppins-bold;
        }
        .container-network{
          background-color: #7B68EE;
          border: 1px solid #7B68EE;
          text-decoration: none;
          margin-right: 20px;
          padding: 10px;
          cursor: pointer;
          border-radius: 10px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 20px;
            height: 20px;
            @include mobile{
              width: 15px;
              height: 15px;
            }
          }
          &.expt{
            margin-right: 0;
          }
        }
      }
    }
    .right-part-explain-remember{
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: calc(60% - 20px);
      padding: 0 50px;
      @include small-device{
        width: 100%;
        margin-top: 30px;
        padding: 0;
      }
      @include mobile{
        justify-content: inherit;
        flex-direction: column;
        align-items: center;
      }
      .container-right-part{
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(40% - 10px);
        @include mobile{
          width: 100%;
          align-items: center;
          margin-bottom: 40px;
        }
        .title-section{
          color: #000;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 10px;
          @include font-poppins-bold;
        }
        .line{
          width: 100%;
          height: 1px;
          margin-bottom: 20px;
          background-color: #000000;
          @include mobile{
            width: 80%;
          }
        }
        .subtitle{
          color: #000;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 16px;
          @include font-poppins-regular;
        }
        .container-info{
          display: flex;
          align-items: flex-start;
          img{
            width: 20px;
            height: 20px;
            margin-right: 15px;
          }
          .content-info{
            display: flex;
            flex-direction: column;
            .title-info{
              color: #000;
              font-size: 16px;
              font-weight: 600;
              margin: 0 0 10px 0;
              @include font-poppins-bold;
            }
            .subtitle{
              color: #000;
              font-size: 16px;
              font-weight: 400;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              @include font-poppins-regular;
              @include mobile{
                max-width: 100px;
              }
            }
          }
        }
      }
    }
  }
  .container-end-footer{
    display: flex;
    align-items: center;
    padding: 50px 50px;
    background-color: black;
    justify-content: space-between;
    @include mobile{
      padding: 20px;
      flex-direction: column;
      justify-content: inherit;
    }
    .title-left{
      color: #ffffff;
      font-size: 16px;
      @include font-poppins-medium;
      @include mobile{
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
    .title-right{
      display: flex;
      align-items: center;
      .title-click{
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        @include font-poppins-medium;
        @include mobile{
          font-size: 12px;
        }
      }
      .line-vertical{
        height: 10px;
        width: 2px;
        margin: 0 10px;
        background-color: #FFFFFF;
      }
    }
  }
}