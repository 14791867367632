.main-not-found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px;

  img{
    width: 80%;

  }
  .button{
    padding: 20px 20px;
    width: 200px;
    background-color: #FFFFFF;
    border: 1px solid #ff9800;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    left: 35px;
    text-align: center;
    &:hover{
      background-color: #ff9800;
    }
  }
}