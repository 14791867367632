.main-contact{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  .background{
    width: 100%;
    height: 686px;
    position: relative;
    @include small-device{
      height: 1000px;
    }
    @include mobile{
      height: 800px;
    }
    .background-img{
      width: 100%;
      height: 100%;
      @include mobile{
        width: 100%;
      }
    }
    .background-cover{
      position: absolute;
      top: 0;
      background-color: rgba(0,0,0,0.8);
      width: 100%;
      height: 100%;
    }
  }

  .container-contact{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    align-items: center;
    top: 278px;
    z-index: 1;
    @include small-device{
      flex-direction: column;
    }
    @include mobile{
      top: 230px;
      position: absolute;

    }
  }
  .container-header{
    display: flex;
    align-items: center;
    @include mobile{
      flex-direction: column;
    }
    .left-part{
      width: 50%;
      max-height: 443px;
      @include mobile{
        width: 100%;
      }
    }
    .container-text-header{
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 0 34px;
      @include mobile{
        width: 100%;
        padding: 20px;
      }
      .title{
        font-size: 24px;
        color: #7B68EE;
        margin-bottom: 11px;
        @include font-kaiseidecol-bold;
        @include mobile{
          font-size: 20px;
        }
      }
      .subtitle{
        font-size: 16px;
        color: black;
        margin-bottom: 44px;
        @include font-poppins-italic;
        @include mobile{
          margin-bottom: 20px;
        }
      }
      .section-description{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title-description{
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 7px;
          @include font-poppins-bold;
        }
        .subtitle-description{
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 23px;
          @include font-poppins-regular;
        }
      }
    }
  }
  .container-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 20px;
    width: 100%;
    .button{
      text-decoration: none;
      padding: 10px 0;
      font-size: 12px;
      width: 128px;
      background-color: #FFFFFF;
      color: #7B68EE;
      border: 1px solid #7B68EE;
      text-align: center;
      border-radius: 5px;
      @include font-poppins-bold;
    }
    .container-network{
      background-color: #fff;
      border: 1px solid #7B68EE;
      text-decoration: none;
      margin-right: 20px;
      padding: 10px;
      cursor: pointer;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile{
        height: 30px;
        width: 30px;
      }
      img{
        width: 20px;
        height: 20px;
        @include mobile{
          width: 15px;
          height: 15px;
        }
      }
      &.expt{
        margin-right: 0;
      }
    }
  }
  .br{
    padding: 150px;
    background: #FFFFFF;
  }
}