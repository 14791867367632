.white-main-header{
  background-color:rgba(0,0,0,0.7);
  transition: all .3s;
}

.main-header{
  background-color:rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  @include mobile{
    padding: 20px;
  }
  .logo{
    margin-right: 30px;
    width: 31px;
    height: 44px;
    cursor: pointer;
    @include mobile{
      width: 30px;
    }
  }
  .link-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    @include small-device{
      display: none;
    }
    .link{
      font-weight: 400;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin-right: 57px;
      @include font-poppins-medium;
      &.responsive{
        @include mobile{
          display: none;
        }
        @include tablet{
          display: none;
        }
      }
      &.exp{
        margin-right: 87px;
      }
    }
    .button{
      cursor:pointer;
      padding:15px 45px;
      background:#00DC81;
      color: #FFFFFF;
      border-radius: 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      @include small-device{
       display: none;
      }
      &:hover {
        background:transparent;
        border: 1px solid #00DC81;
        color: #00DC81;
      }
    }
  }
  .burger-menu{
    display: none;
    @include small-device{
      display: initial;
    }
  }
}