.main-section-1{
  padding: 50px 0 100px 100px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include small-device{
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
  }
  @include tablet{
    padding: 25px 20px;
  }
  @include mobile{
    padding: 25px 20px;
  }
  .left-part{
    position: relative;
    width: calc(55% - 0px);
    .me{
      width: 500px;
      top: 40px;
      left: 60%;
      transform: translateX(-50%);
      position: absolute;
      @include mobile{
        width: 212px;
      }
    }
    .flower{
      width: 100%;
      max-height: 884px;

    }
    @include small-device{
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .right-part{
    width: calc(45% - 0px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include small-device{
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .title{
      text-align: left;
      font-size: 64px;
      margin-bottom: 21px;
      color: black;
      font-weight: bold;
      @include tablet{
        text-align: center;
      }
      @include font-kaiseidecol-bold;
      @include mobile{
        font-size: 24px;
        margin-bottom: 10px;

      }
    }
    .container-subtitle{
      display: flex;
      align-items: center;
      @include tablet{
        justify-content: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      .subtitle{
        font-size: 53px;
        margin-bottom: 61px;
        color: #000;
        font-weight: 500;
        text-align: left;
        @include tablet{
          text-align: center;
        }
        @include font-tirodevanagarihindi-regular;
        @include mobile{
          font-size: 18px;
          margin-bottom: 10px;
        }
        span{
          color:#7B68EE
        }
      }
    }
    .paragraph{
      font-size: 16px;
      margin-bottom: 61px;
      color: #c4c4c4;
      font-weight: 400;
      text-align: left;
      max-width: 467px;
      @include font-poppins-regular;
      @include mobile{
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .button{
      padding: 10px 0;
      font-size: 12px;
      width: 128px;
      background-color: #7B68EE;
      color: #FFFFFF;
      text-align: center;
      border-radius: 5px;
      @include font-poppins-bold;
    }
    &.reverse{
      align-items: flex-start;
      @include tablet{
        align-items: center;
      }

      .title{
        text-align: left;
      }
      .subtitle{
        text-align: left;

      }
      .paragraph{
        text-align: left;
      }
    }
  }
}