.component-button-about{
  padding: 3px 20px;
  background: #7B68EE;
  color: #FFFFFF;
  margin-right: 27px;
  border-radius: 5px;
  @include font-poppins-medium;
  &.reverse{
    background-color: #FFFFFF;
    color: #7B68EE;
  }
  @include tablet{
    font-size: 9px;
    text-align: center;
    margin-right: 13px;
  }
}