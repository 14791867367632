


//Lib


// Utils
@import "./utils/reset";
@import "./utils/sizes";
@import "./utils/mixins";
@import "./utils/_settings";

// Fonts
@import './utils/fonts';

// Layouts
@import "../components/layout/header/main-header/main-header.scss";
@import "../components/layout/header/drop-down-menu/dropdown-menu.scss";
@import "../components/layout/footer/main-footer-1/main-footer-1.scss";
@import "../components/layout/header/burger-menu/burger-menu.scss";
// Components
@import "../components/home/header-page-1/header-page-1.scss";
@import "../components/home/section-1/section-1.scss";
@import "../components/home/section-2/section-2.scss";
@import "../components/home/section-3/section-3.scss";
@import "../components/home/section-4/section-4.scss";
@import "../components/home/section-5/section-5.scss";
@import "../components/about/button-about/button-about.scss";
@import "../components/real/swiper/SwiperLoopReal/swiper-loop-real.scss";
@import "../components/skills/skill-progress-bar/skill-progress-bar.scss";
@import "../components/home/text-rotate-section-1/text-rotate-section-1";
@import "../components/home/text-rotate-header/text-rotate-header.scss";
@import "../components/skills/square-skill-product/square-skill-product.scss";
@import "../components/skills/square-skill-event/square-skill-event.scss";
// Pages
@import "../views/notfound/notfound.scss";
@import "../views/term-policy/termandpolicy.scss";
@import "../views/contact/contact.scss";
@import "../views/about/about.scss";
@import "../views/realisation/real.scss";
@import "../views/skills/skill.scss";




#app {
  height: 100%;
}
body {
  background: #FFFFFF;
  @include font-poppins-regular;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}









