.main-header-page-1{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  .background{
    width: 100%;
    height: 961px;
    background-color: gray;
    @include small-device{
      height: 1000px;
    }
    @include tablet{
      height: 650px;
    }
    @include mobile{
      width: 200%;
      height: 650px;
    }
  }
  .container-global{
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 150px;
    z-index: 1;
    @include small-device{
      flex-direction: column;
    }
    @include mobile{
      padding: 0 20px;
      top: 130px;
    }
    .logo{
      width: 100px;
      margin-bottom: 22px;
      @include tablet{
        width: 80px;
      };
      @include mobile{
        width: 50px;
      }
    }
    .first-text{
      font-size: 53px;
      line-height: 100px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 30px;
      @include font-tirodevanagarihindi-regular;
      @include tablet{
        font-size: 40px;
        line-height: 42px;
      }
      @include mobile{
        font-size: 20px;
        max-width: 300px;
        text-align: center;
        line-height: 24px;
      }
    }
    .subtitle{
      width: 100%;
      position: relative;
      flex-direction: column;
      font-size: 53px;
      color: #fff;
      line-height: 20px;
      margin-bottom: 20px;
      margin-right: 30px;
      @include font-tirodevanagarihindi-italic;
      @include tablet{
        font-size: 40px;
        line-height: 42px;
        margin-right: 0px;
      }
      @include mobile{
        margin-right: 0px;
        font-size: 24px;
        line-height: 28px;
        max-width: 300px;
        text-align: center;
      }
    }
    .container-speener{
      margin-top: 250px;
      position: relative;
      @include tablet{
        margin-top: 50px;
      }
      @include mobile{
        margin-top: 100px;
      }
      .speener{
        width: 140px;
        animation: rotate 10s linear infinite;
        @include mobile{
          width: 100px;
        }
      }
      .arrow{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) rotate(0deg);
        width: 32px;
        @include mobile{
          width: 12px;
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}