.main-section-2{
  background-color: #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .container-section-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -100px;
    @include mobile{
      top: -50px;
    }
    .background{
      max-width: 1040px;
      @include tablet{
        max-width: 700px;
      }
      @include mobile{
        max-width: 382px;
      }
    }
    .title{
      font-size: 48px;
      font-weight: 500;
      color: #000000;
      text-align: center;
      max-width: 960px;
      margin-bottom: 52px;
      @include tablet{
        font-size: 38px;
      }
      @include mobile{
        font-size: 20px;
        margin-bottom: 30px;
      }
      @include font-kaiseidecol-bold;
      span{
        @include font-tirodevanagarihindi-italic;
      }
    }
    .paragraph{
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      max-width: 760px;
      margin-bottom: 57px;
      @include font-poppins-regular;
      @include tablet{
        max-width: 560px;
      }
      @include mobile{
        max-width: 300px;
        font-size: 12px;
      }
    }
    .button{
      padding: 10px 0;
      font-size: 12px;
      width: 128px;
      background-color: #7B68EE;
      color: #FFFFFF;
      border: 1px solid #7B68EE;
      text-align: center;
      border-radius: 5px;
      @include font-poppins-bold;
    }
  }
}