//Variables



html,
body {
  color: #000000;
  background-color: #FFFFFF;
  scroll-behavior: smooth;
  font-family: 'Kameron', serif;
  overflow: auto;
  //overflow-x: hidden ;
  //width: 100%;
  //height: 100%;
  margin: 0;
  padding: 0;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  input{
    -webkit-appearance: none;
  }
}
