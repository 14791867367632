.main-section-4{
  padding: 100px 0;
  margin: 100px 0 ;
  background-color: #7B68EE;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 221px;
  @include mobile{
    flex-direction: column;
    max-height: 500px;
  }
  img{
    width: 246px;
    margin-right: 100px;
    @include mobile{
      margin-right: 0;
    }
  }
  .right{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 48px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
      @include font-tirodevanagarihindi-italic;
      @include tablet{
        font-size: 32px;
      }
      @include mobile{
        font-size: 16px;
      }
    }
    .button{
      padding: 10px 0;
      font-size: 12px;
      text-decoration: none;
      width: 128px;
      background-color: #FFFFFF;
      color: #7B68EE;
      border: 1px solid #7B68EE;
      text-align: center;
      border-radius: 5px;
      @include font-poppins-bold;
    }
  }
}