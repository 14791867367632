.swap-container-section-1 {
  overflow: hidden;
  position: relative;
  top: -20px;
  height: 80px;
  width: 400px;
  margin-left: 10px;
  @include mobile{
    height: 55px;
    width: 221px;
  }
}

.word-section-1 {
  position: absolute;
  bottom: 100%;
  animation: scrollAnimation 1.5s ease-in-out infinite;
  font-size: 53px;
  margin-bottom: 61px;
  color: #7B68EE;
  font-weight: 500;
  text-align: left;
  @include font-tirodevanagarihindi-regular;
  @include mobile{
    font-size: 18px;
    margin-bottom: 10px;
    animation: scrollAnimationMobile 1.5s ease-in-out infinite;

  }/* Ajustez la durée selon vos besoins */
}

@keyframes scrollAnimation {
  0% {
    bottom: -30%; /* Commence en bas */
  }
  20%{
    bottom: -80%;
  }
  100% {
    bottom: 100%; /* Se termine en haut */
  }
}

@keyframes scrollAnimationMobile {
  0% {
    bottom: -10%; /* Commence en bas */
  }
  20%{
    bottom: -30%;
  }
  100% {
    bottom: 20%; /* Se termine en haut */
  }
}
