.component-skill-event-square{
  border-radius: 7px;
  background-color: #ffffff;
  width: calc(50% - 15px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 178px;
  &:nth-child(2n + 2){
    margin: 0;
  }
  .container-skills-event-square{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    .img-skill{
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
    }
    .title-skill{
      font-size: 22px;
      @include font-poppins-medium;
      color: #000000;
      margin-bottom: 10px;
    }
    .subtitle{
      text-align: center;
      font-size: 16px;
      @include font-poppins-light;
      color: #000000;
    }
    &.no-padding{
      padding: 0;
    }
    .img-cliked{
      width: 100%;
    }
  }
}