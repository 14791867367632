.swap-container {
  overflow: hidden;
  position: relative;
  top: -20px;
  height: 80px;
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile{
    height: 83px;
    width: 300px;
  }
}

.word {
  position: absolute;
  bottom: 100%;
  animation: scrollAnimationHeader 1.5s ease-in-out infinite;
  font-size: 53px;
  margin-bottom: 61px;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
  line-height: 56px;
  margin-right: 30px;
  @include font-tirodevanagarihindi-italic;
  @include tablet{
    font-size: 40px;
    line-height: 42px;
    margin-right: 0px;
  }
  @include mobile{
    animation: scrollAnimationMobileHeader 1.5s ease-in-out infinite;
    margin-right: 0px;
    font-size: 20px;
    line-height: 22px;
    max-width: 300px;
    text-align: center;
  }

}

@keyframes scrollAnimationHeader {
  0% {
    bottom: -30%; /* Commence en bas */
  }
  20%{
    bottom: -80%;
  }
  100% {
    bottom: 100%; /* Se termine en haut */
  }
}

@keyframes scrollAnimationMobileHeader {
  0% {
    bottom: -10%; /* Commence en bas */
  }
  20%{
    bottom: -30%;
  }
  100% {
    bottom: 20%; /* Se termine en haut */
  }
}
