.main-conditions{
  display: flex;
  flex-direction: column;
  padding: 200px 50px;
  @include mobile{
    padding: 100px 20px;
  }
  .term-container{
    display: flex;
    flex-direction: column;
    .title-term{
      font-size: 40px;
      font-weight: 700;
      color: black;
      margin-bottom: 30px;
      @include mobile{
        font-size: 30px;
      }
    }
    .subtitle-term{
      font-size: 20px;
      font-weight: 500;
      color: #c4c4c4;
      margin-bottom: 20px;
      @include mobile{
        font-size: 16px;
      }
    }
  }
}