.main-section-5{
  padding: 100px 0;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include mobile{
    padding: 0px;
  }
  .title{
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 71px;
    @include font-tirodevanagarihindi-italic;
    @include mobile{
      font-size: 34px;
    }
  }
  .container-section-5{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .section-collab{
      display: flex;
      flex-direction: column;
      position: relative;
      width: calc(25% - 0px);
      &:nth-child(4n + 4){
        margin: 0;
      }
      @include tablet{
        width: calc(50% - 0px);
        &:nth-child(2n + 2){
          margin: 0;
        }
      }
      @include mobile{
        width: calc(100% - 0px);
        &:nth-child(1n + 1){
          margin: 0;
        }
      }
      &.expt{
        margin-left: 0;
      }
      .img-ad{
        .image{
          width: 200%;
          height: 552px;
        }
        .background{
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .container-title{
        position: absolute;
        top: 75%;
        left: 20px;
        display: flex;
        align-items: center;
        padding: 20px;
        .content-text{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title{
            font-size: 26px;
            color: #FFFFFF;
            font-weight: 400;
            margin-bottom: 5px;
            @include font-tirodevanagarihindi-regular;
            @include mobile{
              font-size: 25px;
            }
          }
          .subtitle{
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 400;
            @include font-tirodevanagarihindi-italic;
          }
        }
        .arrow-left{
          margin-left: 30px;
          width: 50px;
        }
      }
      .container-title-hover{
        flex-direction: column;
        padding: 30px;
        position: absolute;
        display: none;

        .header-section{
          display: flex;
          flex-direction: row;
          align-items: center;
          .perso-ref{
            width:70px;
            height: 70px;
            border-radius: 50%;
            margin-right: 20px;
          }
          .title-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title{
              font-size: 17px;
              color: #fff;
              margin-bottom: 3px;
              @include font-tirodevanagarihindi-regular;
            }
            .subtitle{
              @include font-tirodevanagarihindi-italic;
              font-size: 13px;
              color: #fff;
            }
          }
        }
        .content{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 12px;
          position: absolute;
          bottom: -414px;
          margin-top: 10px;
          color: #FFFFFF;
          max-width: 400px;
          @include font-poppins-regular;
          @include tablet{
            font-size: 12px;
          }
          @include mobile{
            font-size: 10px;
          }
        }
      }
      &:hover{
        .container-title-hover{
          display: flex;
        }
        .container-title{
          display: none;
        }
        .img-ad{
          .background{
            background-color: rgba(0,0,0,0.8);
          }
        }
      }
    }
  }
}