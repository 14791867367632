.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 57px;
  .title-button{
    display: flex;
    align-items: center;
    .dropdown-toggle {
      color: #000000;
      padding: 10px 20px;
      font-size: 20px;
      border: none;
      cursor: pointer;
    }
    img{
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 150px;
  }
  .show {
    display: block;
  }

  .dropdown-item {
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #f2f2f2;
    }

    .img-drop-down{
      margin-right: 10px;
      width: 10px;
      height: 10px;
    }
  }

}






