/*  COLORS
========================================== */
$black: #000000;
$white: #ffffff;
$gray: #2a2a2a;
$gold: #000;

/*  FONTS
========================================== */

$raleway: 'Raleway', sans-serif;
$spectral: 'Spectral', serif;


/*  MIXINS
========================================== */

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin burger($size, $thickness, $spacing, $color) {
  display: block;
  width: $size;
  height: $thickness;
  background-color: $color;
  margin: $spacing 0;
  transition: background-color 0.3s ease-in-out;
}

@mixin transition($transition) {
  -webkit-transition: #{$transition};
  -moz-transition: #{$transition};
  -o-transition: #{$transition};
  transition: #{$transition};
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: #{$transition-delay};
  -moz-transition-delay: #{$transition-delay};
  -o-transition-delay: #{$transition-delay};
  transition-delay: #{$transition-delay};
}

@mixin burger-to-cross {
  span {
    &:before,
    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 3px;
      background-color: $gray; // You can customize the color here
      position: absolute;
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    &:before {
      transform: translateY(-9px);
    }

    &:after {
      transform: translateY(9px);
    }

    &.clicked {
      &:before {
        transform: rotate(-45deg) translateY(0) translateX(0);
      }

      &:after {
        transform: rotate(45deg) translateY(0) translateX(0);
      }
    }
  }
}


.burger {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  z-index: 10;

  .logo-burger{
    width: 50px;
    height: 40px;
    position: absolute;
    top: 20%;
    left: 20%;
    margin-left: -15px;
    margin-top: -1.5px;
    @include mobile{
      width: 30px;
      height: 20px;
    }
  }

  span {
    @include burger(30px, 3px, 6px, $white); // gray
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -1.5px;
  }

  &.clicked {
    span {
      @include burger-to-cross;

      &:before, &:after {
        background-color: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}


nav {
  background-color: $gray;
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 515px;
  width: 100%;
  padding: 100px 40px 60px 40px;
  overflow-y: auto;
  @include transform(translateX(100%));
  @include transition(transform .55s cubic-bezier(0.785, 0.135, 0.15, 0.86));

  &.show {
    @include transform(translateX(0px));

    ul.main {
      li {
        @include transform(translateX(0px));
        opacity: 1;

        @for $i from 1 to 10 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 0.15s;
          }
        }
      }
    }

    .about, .social, ul.sub {
      @include transform(translateY(0px));
      opacity: 1;
      transition-delay: .85s;
    }
  }

  @media (min-width: 667px) {
    padding: 120px 90px 70px 90px;
  }

  ul.main {
    list-style-type: none;

    li {
      margin-bottom: 20px;
      @include transform(translateX(40px));
      opacity: 0;
      @include transition(all .3s ease);

      &:last-of-type {
        margin-bottom: 0px;
      }

      a {
        color: $white;
        font-family: $raleway;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.5rem;
        display: block;
        letter-spacing: 5px;
        font-weight: 600;
        padding: 10px 0;
        @include transition(all .3s ease);

        span {
          color: $gold;
        }

        &:hover {
          color: $gold;
        }
      }
    }
  }

  .about {
    margin-top: 40px;
    @include transform(translateY(30px));
    opacity: 0;
    @include transition(all .4s ease);

    p {
      color: $white;
      font-family: $spectral;
      font-size: 1.05rem;
      letter-spacing: 0.5px;
      line-height: 170%;
    }
  }


  ul.sub {
    list-style-type: none;
    margin-top: 40px;
    @include transform(translateY(30px));
    opacity: 0;
    @include transition(all .4s ease);

    li {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      a {
        color: $white;
        font-family: $raleway;
        letter-spacing: 1px;
        font-size: 0.9rem;
        text-decoration: none;
        @include transition(all .3s ease);

        &:hover {
          color: $gold;
        }
      }
    }
  }
}


/*  OVERLAY
========================================== */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height:100%;
  @include transition(all 0.3s ease-in-out);
  background-color: $gold;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 0.8;
    visibility: visible;
  }
}